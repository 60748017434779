import React, { Component } from 'react';
import ReactDataGrid from 'react-data-grid';
import { range } from 'lodash';
import moment from 'moment';
import './Backend.css';
import { Container, Row, Col } from 'react-bootstrap';
import Calendar from 'react-calendar';

require('dotenv').config();

const defaultParsePaste = str => (
    str.split(/\r\n|\n|\r/)
        .map(row => row.split('\t'))
);

const columns = [
    { key: 'Car', name: 'Bíll', resizable: true, width: 300 },
    { key: '0-2-hours', name: '0-2 Tímar', editable: true },
    { key: '3-4-hours', name: '3-4 Tímar', editable: true },
    { key: '5-8-hours', name: '5-8 Tímar', editable: true },
    { key: '9-24-hours', name: '9-24 Tímar', editable: true },
    { key: '2-6-days', name: '2-6 Dagar', editable: true },
    { key: '7-13-days', name: '7-13 Dagar', editable: true },
    { key: '14-20-days', name: '14-20 Dagar', editable: true },
    { key: '21-30-days', name: '21-30 Dagar', editable: true },
];

class AddEditPrice extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cars: [],
            topLeft: {},
            botRight: {},
            response: null,
            rows: null,
            name: '',
            dateFrom: '',
            dateTo: '',
            default: false,
            price: null,
            isLoading: true,
            error: null,
            status: 200
        }

        document.addEventListener('copy', this.handleCopy);
        document.addEventListener('paste', this.handlePaste);
    }

    componentWillUnmount() {
        this.removeAllListeners();
    }

    removeAllListeners = () => {
        document.removeEventListener('copy', this.handleCopy);
        document.removeEventListener('paste', this.handlePaste);
    }

    componentDidMount() {
        this.fetchCars();
        const { match: { params } } = this.props;
        if (params.id !== 'create') {
            this.fetchPrice("/" + params.id);

        } else {
            this.setState({
                creating: true,
            });
            this.fetchPrice("/5dde8a647c213e56251a5c5c");
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({ error: null, })
        this.fetchSite(newProps.match.url);
    }

    rowGetter = (i) => {
        const { rows } = this.state;
        return rows[i];
    }

    handleCopy = (e) => {
        e.preventDefault();
        const { topLeft, botRight } = this.state;
        // Loop through each row
        const text = range(topLeft.rowIdx, botRight.rowIdx + 1).map(
            // Loop through each column
            rowIdx => columns.slice(topLeft.colIdx, botRight.colIdx + 1).map(
                // Grab the row values and make a text string
                col => this.rowGetter(rowIdx)[col.key],
            ).join('\t'),
        ).join('\n');
        e.clipboardData.setData('text/plain', text);
    }

    updateRows = (startIdx, newRows) => {
        this.setState((state) => {
            const rows = state.rows.slice();
            for (let i = 0; i < newRows.length; i++) {
                if (startIdx + i < rows.length) {
                    rows[startIdx + i] = { ...rows[startIdx + i], ...newRows[i] };
                }
            }
            return { rows };
        });
        this.tableDataToJson();
    }

    handlePaste = (e) => {
        e.preventDefault();
        const newRows = [];
        const pasteData = defaultParsePaste(e.clipboardData.getData('text/plain'));
        if (pasteData[pasteData.length - 1][0] === "") {
            pasteData.pop();
        }
        const { topLeft } = this.state;

        pasteData.forEach((row) => {
            const rowData = {};
            // Merge the values from pasting and the keys from the columns
            columns.slice(topLeft.colIdx, topLeft.colIdx + row.length)
                .forEach((col, j) => {
                    // Create the key-value pair for the row
                    rowData[col.key] = row[j];
                });
            // Push the new row to the changes
            newRows.push(rowData);
        });
        this.updateRows(topLeft.rowIdx, newRows);
    }

    fetchCars() {
        fetch(process.env.REACT_APP_NODE_BACKEND + '/cars')
            // We get the API response and receive data in JSON format...
            .then(response => {
                this.setState({ status: response.status })
                return response.json();
            })
            // ...then we update the users state
            .then(data => {
                this.setState({
                    cars: data,
                    isLoading: false,
                })
            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }

    fetchPrice(id) {
        // Where we're fetching data from api+
        fetch(process.env.REACT_APP_NODE_BACKEND + '/prices' + id)
            // We get the API response and receive data in JSON format...
            .then(response => {
                this.setState({ status: response.status })
                return response.json();
            })
            // ...then we update the users state
            .then(data => {
                this.setState({
                    name: data.name,
                    dateFrom: this.state.creating ? new Date() : new Date(data.dateFrom),
                    dateTo: this.state.creating ? new Date() : new Date(data.dateTo),
                    defaultPrice: data.default,
                    price: data.price,
                    isLoading: false,
                    default: data.default,
                });
                this.mapPriceToTable();
            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }

    createPrice = (event) => {
        event.preventDefault();
        fetch(process.env.REACT_APP_NODE_BACKEND + '/prices', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({
                name: this.state.name,
                dateFrom: this.state.dateFrom,
                dateTo: this.state.dateTo,
                default: false,
                price: this.state.price
            })
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.text().then(text => {
                        return new Promise((resolve, reject) => {
                            throw Error(text);
                        })
                    });
                }
            })
            .then(data => {
                this.props.history.goBack();
            })
            .catch(error => this.setState({ error, isLoading: false }));
    }

    updatePrice = (event) => {
        const { match: { params } } = this.props;
        fetch(process.env.REACT_APP_NODE_BACKEND + '/prices/' + params.id, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({
                name: this.state.name,
                dateFrom: this.state.dateFrom,
                dateTo: this.state.dateTo,
                default: this.state.default,
                price: this.state.price
            }),
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.text().then(text => {
                        return new Promise((resolve, reject) => {
                            throw Error(text);
                        })
                    });
                }
            })
            .then(data => {
                this.props.history.goBack();
            })
            .catch(error => this.setState({ error, isLoading: false }));
    }

    onSubmit = async (event) => {
        event.preventDefault();
        this.setState({ isSubmitted: true });
        await this.tableDataToJson();
        if (this.state.creating) {
            this.createPrice(event);
        } else {
            this.updatePrice(event);
        }
    }

    createRow(item) {
        const { cars } = this.state;
        const car = cars.find(car => car._id === item.car);
        let tmp = { Car: car.name }
        for (let index = 0; index < item.prices.length; index++) {
            const element = item.prices[index];
            let colName = (element.minTime + "-" + element.maxTime + "-" + element.timeType);
            tmp[colName] = element.price;
        }
        return (tmp);
    }

    async mapPriceToTable() {
        var tmpRows = this.state.price.map(this.createRow.bind(this));
        this.setState({ rows: tmpRows });
        
    }

    tableDataToJson() {
        return new Promise(resolve => {
            const { rows, price } = this.state;
            this.setState(state => {
                for (let index = 0; index < rows.length; index++) {
                    const element = rows[index];
                    let length = Object.keys(element).length
                    for (let index2 = 1; index2 < length; index2++) {
                        let key = Object.keys(element)[index2];
                        const value = element[key];
                        price[index].prices[index2 - 1].price = parseFloat(value);
                    }
                }
                return { price }
            }, () => { resolve() })
        });
    }

    onGridRowsUpdated = (event) => {
        this.setState(state => {
            const rows = state.rows.slice();
            for (let i = event.fromRow; i <= event.toRow; i++) {
                rows[i] = { ...rows[i], ...event.updated };
            }
            return { rows };
        });
        this.tableDataToJson();
    };

    onCellSelected = ({ rowIdx, idx }) => {
        this.setState({
            rowSelected: rowIdx, colSelected: idx,
            topLeft: {
                rowIdx: rowIdx,
                colIdx: idx,
            },
            botRight: {
                rowIdx: rowIdx,
                colIdx: idx,
            },
        });
    };

    setSelection = (args) => {
        this.setState({
            topLeft: {
                rowIdx: args.topLeft.rowIdx,
                colIdx: args.topLeft.idx,
            },
            botRight: {
                rowIdx: args.bottomRight.rowIdx,
                colIdx: args.bottomRight.idx,
            },
        });
    };

    onNameChange = event => {
        this.setState({ name: event.target.value });
    }

    onChangeDateFrom = event => {
        this.setState({ dateFrom: event });
    }

    onChangeDateTo = event => {
        this.setState({ dateTo: event });
    }

    render() {
        const { error, isLoading, status, creating, rows, name, dateTo, dateFrom, defaultPrice } = this.state;
        //const rows = [{id: 0, title: 'row1', count: 20}, {id: 1, title: 'row1', count: 40}, {id: 2, title: 'row1', count: 60}];
        if (status === 404) {
            return (<div className="Backend">
                <h1>404</h1>
            </div>)
        }
        else {
            return (
                <div className="Backend">
                    <Container>
                        <Row><Col><h1 className="Title">Sendibílaleiga {creating ? ('búa til verð') : ('breyta verði')}</h1></Col></Row>
                        {!isLoading && rows ? (

                            <div>
                                <form onSubmit={this.onSubmit}>
                                    {!defaultPrice || creating ? (<Row>
                                        <Col>
                                            <label htmlFor="name">Nafn tímabils</label>
                                            <Row>
                                                <Col ><input className="form-control" type="text" name="name" id="name" value={name} onChange={this.onNameChange} ></input> </Col>
                                            </Row>
                                            <label htmlFor="dateFrom">Upphafsdagur</label>
                                            <Row>
                                                <Col ><input className="form-control" type="text" name="dateFrom" id="dateFrom" value={moment(dateFrom).format('dddd DD MMMM YYYY')} readOnly></input> </Col>
                                            </Row>
                                            <label htmlFor="dateTo">Endadagur</label>
                                            <Row>
                                                <Col ><input className="form-control" type="text" name="dateTo" id="dateTo" value={moment(dateTo).format('dddd DD MMMM YYYY')} readOnly></input> </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col><Calendar
                                                    onChange={this.onChangeDateFrom}
                                                    value={dateFrom}
                                                    minDate={new Date()}
                                                /></Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col><Calendar
                                                    onChange={this.onChangeDateTo}
                                                    value={dateTo}
                                                    minDate={dateFrom}
                                                /></Col>
                                            </Row>
                                        </Col>
                                    </Row>) : (<Row><Col><h2>Þú ert að breyta grunn verði</h2></Col></Row>)}
                                    <Row style={{paddingTop: '20px'}}>
                                        <ReactDataGrid
                                            
                                            height={'100%'}
                                            columns={columns}
                                            rowGetter={i => rows[i]}
                                            rowsCount={rows.length}
                                            enableRowSelect={false}
                                            onCellSelected={this.onCellSelected}
                                            onGridRowsUpdated={this.onGridRowsUpdated}
                                            enableCellSelect={true}
                                            cellRangeSelection={{
                                                onComplete: this.setSelection,
                                            }}
                                        />
                                    </Row>
                                    <Row>
                                        <Col xs={10}>{error ? <p className="Error">Oops eitthvað fór úrskeiðis: {error.message}</p> : null}</Col>
                                        <Col xs={2}><button className="submitButton" type="submit">SUBMIT</button></Col>
                                    </Row>
                                </form>
                            </div>
                            // If there is a delay in data, let's let the user know it's loading
                        ) : (
                                <h3>Loading...</h3>
                            )}
                    </Container>
                </div>
            );
        }
    }
}

export default AddEditPrice;