import React, { Component } from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import moment from 'moment';
import Calendar from "react-calendar";

import './Backend.css';

require('dotenv').config();

class EditSite extends Component {

  constructor(props) {
    super(props);

    this.state = {
      response: null,
      name: '',
      dateFrom: '',
      dateTo: '',
      discount: '',
      locations: [],
      checkedLocations: [],
      cars: [],
      checkedCars: [],
      isLoading: true,
      error: null,
      status: 200,
    }
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    this.fetchCars();
    this.fetchLocations();
    if (params.id !== 'create') {
      this.fetchOffer("/" + params.id);
    } else {
      this.setState({
        isLoading: false,
        creating: true,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({ error: null, })
    this.fetchOffer(newProps.match.url);
  }

  fetchOffer(id) {

    // Where we're fetching data from api+
    fetch(process.env.REACT_APP_NODE_BACKEND + '/offers' + id)
      // We get the API response and receive data in JSON format...
      .then(response => {
        this.setState({ status: response.status })
        return response.json();
      })
      // ...then we update the users state
      .then(data => {
        this.setState({
          response: data,
          name: data.name,
          dateFrom: this.state.creating ? new Date() : new Date(data.dateFrom),
          dateTo: this.state.creating ? new Date() : new Date(data.dateTo),
          discount: data.discount,
          checkedLocations: data.locations,
          checkedCars: data.cars,
          isLoading: false,
          creating: false,
          error: null,
          status: 200,
        })
      })
      // Catch any errors we hit and update the app
      .catch(error => this.setState({ error, isLoading: false }));
  }

  fetchLocations() {
    fetch(process.env.REACT_APP_NODE_BACKEND + '/locations')
      // We get the API response and receive data in JSON format...
      .then(response => {
        this.setState({ status: response.status })
        return response.json();
      })
      // ...then we update the users state
      .then(data => {
        this.setState({
          locations: data,
        })
      })
      // Catch any errors we hit and update the app
      .catch(error => this.setState({ error, isLoading: false }));
  }
  fetchCars() {
    fetch(process.env.REACT_APP_NODE_BACKEND + '/cars')
      // We get the API response and receive data in JSON format...
      .then(response => {
        this.setState({ status: response.status })
        return response.json();
      })
      // ...then we update the users state
      .then(data => {
        this.setState({
          cars: data,
        })
        let cars = [];
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          if (element.locations.includes(this.state.id)) {
            cars.push(element._id);
          }
        }
        this.setState({ checkedCars: cars });
      })
      // Catch any errors we hit and update the app
      .catch(error => this.setState({ error, isLoading: false }));
  }

  createOffer = (event) => {
    event.preventDefault();
    fetch(process.env.REACT_APP_NODE_BACKEND + '/offers', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: this.state.name,
        dateFrom: this.state.dateFrom,
        dateTo: this.state.dateTo,
        discount: this.state.discount,
        locations: this.state.checkedLocations,
        cars: this.state.checkedCars,
      })
    })
      .then(response => {

        if (response.ok) {
          return response.json();
        } else if (response.status === 409) {
          throw Error("Tilboð með þessu nafni er nú þegar til");
        }
        else {
          throw Error("Rejected with code " + response.status);
        }

      })
      .then(data => {
        this.props.history.goBack();
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  updateOffer = (event) => {
    event.preventDefault();
    const { match: { params } } = this.props;
    fetch(process.env.REACT_APP_NODE_BACKEND + '/offers/' + params.id, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: this.state.name,
        dateFrom: this.state.dateFrom,
        dateTo: this.state.dateTo,
        discount: this.state.discount,
        locations: this.state.checkedLocations,
        cars: this.state.checkedCars,
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 409) {
          throw Error("Tilboð með þessu nafni er nú þegar til");
        }
        else {
          throw Error("Rejected with code " + response.body);
        }
      })
      .then(data => {
        this.props.history.goBack();
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  onSubmit = (event) => {
    event.preventDefault();
    if (this.state.creating) {
      this.createOffer(event);
    } else {
      this.updateOffer(event);
    }
  }

  onNameChange = event => {
    this.setState({ name: event.target.value });
  }

  onDiscountChange = event => {
    this.setState({ discount: event.target.value });
  }

  onChangeDateFrom = event => {
    const tmp = new Date(event)
    tmp.setHours(0);
    tmp.setMinutes(0);
    tmp.setSeconds(0);
    this.setState({ dateFrom: tmp })
  }

  onChangeDateTo = event => {
    const tmp = new Date(event)
    tmp.setHours(23);
    tmp.setMinutes(59);
    tmp.setSeconds(59);
    this.setState({ dateTo: tmp })
  }

  onCarChange(event) {
    let checkedArray = this.state.checkedCars;
    let selectedValue = event.target.value;
    if (event.target.checked === true) {
      checkedArray.push(selectedValue);
      this.setState({
        checkedCars: checkedArray
      });
    } else {
      let valueIndex = checkedArray.indexOf(selectedValue);
      checkedArray.splice(valueIndex, 1);
      this.setState({ checkedCars: checkedArray });
    }
  }

  onLocationChange(event) {
    let checkedArray = this.state.checkedLocations;
    let selectedValue = event.target.value;
    if (event.target.checked === true) {
      checkedArray.push(selectedValue);
      this.setState({
        checkedLocations: checkedArray
      });
    } else {
      let valueIndex = checkedArray.indexOf(selectedValue);
      checkedArray.splice(valueIndex, 1);
      this.setState({
        checkedLocations: checkedArray
      });
    }
  }

  createCarList(item) {
    let checked = false;
    if (this.state.checkedCars.includes(item._id)) {
      checked = true;
    }
    return (
      <Row key={item._id}>
        <Col xs={12}>
          <input type="checkbox" defaultChecked={checked} value={item._id} id={item._id} onChange={this.onCarChange.bind(this)} /><label htmlFor={item._id}>{item.name}</label>
        </Col>
      </Row>
    )
  }

  createLocationList(item) {
    let checked = false;
    if (this.state.checkedLocations.includes(item._id)) {
      checked = true;
    }
    return (
      <Row key={item._id}>
        <Col xs={12}>
          <input type="checkbox" defaultChecked={checked} value={item._id} id={item._id} onChange={this.onLocationChange.bind(this)} /><label htmlFor={item._id}>{item.name}</label>
        </Col>
      </Row>
    );
  }

  render() {
    const { status, creating, isLoading, error, cars, locations, name, discount, dateFrom, dateTo } = this.state;

    var carCheckBoxes = cars.map(this.createCarList.bind(this));
    var locationCheckBoxes = locations.map(this.createLocationList.bind(this));

    if (status === 404) {
      return (
        <div className="Backend">
          <h1>404</h1>
        </div>)
    } else {
      return (
        <div className="Backend">
          <Container>
            <Row><Col> <h1 className="Title">Sendibílaleiga {creating ? ('búa til tilboð') : ('breyta Tilboði')}</h1> </Col></Row>
            {!isLoading ? (
              <div>
                <form onSubmit={this.onSubmit}>
                  <Tabs defaultActiveKey="offer" id="OffersAddEditTab">
                    <Tab eventKey="offer" title="Tilboð">
                      <Row>
                        <Col>
                          <label htmlFor="offerName">Nafn</label>
                          <Row>
                            <Col xs={10}><input className="form-control" type="text" name="name" value={name} id="offerName" onChange={this.onNameChange} required /></Col>
                          </Row>
                          <label htmlFor="dateFrom">Gildir frá</label>
                          <Row>
                            <Col xs={10}><input className="form-control" type="text" name="dateFrom" id="dateFrom" value={moment(dateFrom).format('dddd DD MMMM YYYY')} readOnly></input> </Col>
                          </Row>
                          <label htmlFor="dateTo">Gildir til</label>
                          <Row>
                            <Col xs={10}><input className="form-control" type="text" name="dateTo" id="dateTo" value={moment(dateTo).format('dddd DD MMMM YYYY')} readOnly></input> </Col>
                          </Row>
                          <label htmlFor="offerDiscount">Afsláttur (Í prósentu %)</label>
                          <Row>
                            <Col xs={3}><input className="form-control" type="text" name="discount" value={discount} id="offerDiscount" onChange={this.onDiscountChange} required /></Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col><Calendar
                              onChange={this.onChangeDateFrom}
                              value={dateFrom}
                              minDate={new Date()}
                            /></Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col><Calendar
                              onChange={this.onChangeDateTo}
                              value={dateTo}
                              minDate={dateFrom}
                            /></Col>
                          </Row>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="settings" title="Stillingar">
                      <Row>
                        <Col>
                          <label htmlFor="carLocations">Bílar</label>
                          {carCheckBoxes}
                        </Col>
                        <Col>
                          <label htmlFor="carLocations">Staðsetningar</label>
                          {locationCheckBoxes}
                        </Col>
                      </Row>
                    </Tab>
                  </Tabs>
                  <Row>
                    <Col xs={10}>{error ? <p className="Error">Oops eitthvað fór úrskeiðis: {error.message}</p> : null}</Col>
                    <Col xs={2}><button className="submitButton" type="submit">SUBMIT</button></Col>
                  </Row>
                </form>
              </div>
            ) : (
                <h3>Loading...</h3>
              )
            }
          </Container>
        </div>
      );
    }

  }
}

export default EditSite;