import React, { Component } from 'react';

import './Backend.css';
import { Container, Row, Col } from 'react-bootstrap';

require('dotenv').config();

class UserAddEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      response: null,
      name: '',
      email: '',
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      isLoading: true,
      error: null,
      status: 200
    }
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    if (params.id !== 'create') {
      this.setState({ id: params.id });
      this.fetchUser("/" + params.id);
    } else {
      this.setState({
        isLoading: false,
        creating: true,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({ error: null, })
    this.fetchUser(newProps.match.url);
  }

  fetchUser(id) {
    // Where we're fetching data from api+
    fetch(process.env.REACT_APP_NODE_BACKEND + '/users' + id, {
      method: 'GET',
      credentials: 'include'
    })
      // We get the API response and receive data in JSON format...
      .then(response => {
        this.setState({ status: response.status })
        return response.json();
      })
      // ...then we update the users state
      .then(data => {
        this.setState({
          response: data,
          name: data.name,
          email: data.email,
          isLoading: false,
          creating: false,
        })
      })
      // Catch any errors we hit and update the app
      .catch(error => this.setState({ error, isLoading: false }));
  }

  createUser = (event) => {
    event.preventDefault();
    fetch(process.env.REACT_APP_NODE_BACKEND + '/users', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        name: this.state.name,
        email: this.state.email,
        password: this.state.newPassword,
        default: false,
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 409) {
          throw Error("Notandi með þetta email er nú þegar til");
        } else {
          throw Error("Rejected with code " + response.status);
        }
      })
      .then(data => {
        this.props.history.goBack();
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  updatePassword = (event) => {
    event.preventDefault();
    const { match: { params } } = this.props;
    fetch(process.env.REACT_APP_NODE_BACKEND + '/users/' + params.id, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        oldPassword: this.state.oldPassword,
        newPassword: this.state.newPassword
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          throw Error("Núverandi lykilorð er ekki rétt");
        } else if (response.status === 404) {
          throw Error("Notandi fannst ekki");
        } else {
          throw Error("Rejected with code " + response.body);
        }
      })
      .then(data => {
        this.props.history.goBack();
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  onSubmit = (event) => {
    event.preventDefault();
    const { newPassword, newPasswordConfirm } = this.state;
    if (newPassword === newPasswordConfirm && newPassword.length >= 8) {
      if (this.state.creating) {
        this.createUser(event);
      } else {
        this.updatePassword(event);
      }
    } else {
      const error = { message: "Bæði lykilorðin verða að vera eins og lágmark 8 á lengd" }
      this.setState({ error: error });
    }
  }

  onNameChange = event => {
    this.setState({name: event.target.value});
  }
  onEmailChange = event => {
    this.setState({email: event.target.value});
  }
  onNewPasswordConfirmchange = event => {
    this.setState({ newPasswordConfirm: event.target.value });
  }
  onNewPasswordchange = event => {
    this.setState({ newPassword: event.target.value });
  }
  onOldPasswordchange = event => {
    this.setState({ oldPassword: event.target.value })
  }

  render() {
    const { isLoading, creating, error, oldPassword, email, name, newPassword, newPasswordConfirm } = this.state;

    return (
      <div className="Backend">
        <Container>
          <Row><Col><h1 className="Title">Sendibílaleiga {creating ? ('búa til notanda') : ('breyta passwordi')}</h1></Col></Row>
          {!isLoading ? (
            <form onSubmit={this.onSubmit}>
              <Col>
                <label htmlFor="Name">Nafn</label>
                <Row>
                  <Col md={4} xl={4}><input className="form-control" type="text" required name="name" id="Name" value={name} onChange={this.onNameChange} readOnly={creating ? (false) : (true)}></input> </Col>
                </Row>
                <label htmlFor="Email">Email</label>
                <Row>
                  <Col md={4} xl={4}><input className="form-control" type="email" required name="Email" id="Email" value={email} onChange={this.onEmailChange} readOnly={creating ? (false) : (true)}></input> </Col>
                </Row>
                {!creating ? (<div><label htmlFor="oldPassword">Núverandi lykilorð</label>
                  <Row>
                    <Col md={4} xl={4}><input className="form-control" type="password" name="oldPassword" id="oldPassword" onChange={this.onOldPasswordchange} value={oldPassword} required={creating ? (true) : (false)}></input> </Col>
                  </Row></div>) : (null)}
                
                <label htmlFor="newPassword">Nýtt lykilorð</label>
                <Row>
                  <Col md={4} xl={4}><input className="form-control" type="password" name="newPassword" id="oldnewPasswordPassword" onChange={this.onNewPasswordchange} value={newPassword} required={creating ? (true) : (false)}></input> </Col>
                </Row>
                <label htmlFor="newPasswordConfirm">Nýtt lykilorð staðfesting</label>
                <Row>
                  <Col md={4} xl={4}><input className="form-control" type="password" name="newPasswordConfirm" id="newPasswordConfirm" onChange={this.onNewPasswordConfirmchange} value={newPasswordConfirm} required={creating ? (true) : (false)}></input> </Col>
                </Row>
              </Col>
              <Row>
                <Col xs={10}>{error ? <p className="Error">Oops eitthvað fór úrskeiðis: {error.message}</p> : null}</Col>
                <Col xs={2}><button className="submitButton" type="submit">{creating ? ('Búa til notanda'): ('Uppfæra lykilorð')}</button></Col>
              </Row>

            </form>
          ) : (
              <h3>Loading...</h3>
            )}
        </Container>
      </div>
    )

  }
}

export default UserAddEdit;