import React, { Component } from 'react';

import './Backend.css';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import ReactLoading from 'react-loading';
import ImageService from '../../services/ImageService';

require('dotenv').config();

class categoryAddEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            response: null,
            name: '',
            link: '',
            description: '',
            cars: [],
            image: '',
            oldimage: '',
            metaTitle: '',
            metaKeywords: '',
            metaDescription: '',
            isLoading: true,
            isUploading: false,
            error: null,
            status: 200,
            order: 0,
        }
    }

    fetchSite(id) {
        // Where we're fetching data from api+
        fetch(process.env.REACT_APP_NODE_BACKEND + '/categories' + id)
            // We get the API response and receive data in JSON format...
            .then(response => {
                this.setState({ status: response.status })
                return response.json();
            })
            // ...then we update the users state
            .then(data => {
                this.setState({
                    response: data,
                    name: data.name,
                    link: data.link,
                    description: data.description,
                    cars: data.cars,
                    image: data.image,
                    metaTitle: data.metaTitle,
                    metaKeywords: data.metaKeywords,
                    metaDescription: data.metaDescription,
                    isLoading: false,
                    creating: false,
                    order: data.order,
                })
            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }

    createCategory = (event) => {
        event.preventDefault();
        fetch(process.env.REACT_APP_NODE_BACKEND + '/categories', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({
                name: this.state.name,
                link: this.state.link,
                description: this.state.description,
                cars: this.state.cars,
                image: this.state.image,
                metaTitle: this.state.metaTitle,
                metaKeywords: this.state.metaKeywords,
                metaDescription: this.state.metaDescription,
                order: this.state.order,
            })
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 409) {
                    throw Error("Flokkur með þessum link er nú þegar til");
                }
                else {
                    throw Error("Rejected with code " + response.status);
                }

            })
            .then(data => {
                this.props.history.goBack();
            })
            .catch(error => this.setState({ error, isLoading: false }));
    }

    updateCategory = (event) => {
        event.preventDefault();
        const { match: { params } } = this.props;
        fetch(process.env.REACT_APP_NODE_BACKEND + '/categories/' + params.id, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({
                name: this.state.name,
                link: this.state.link,
                description: this.state.description,
                cars: this.state.cars,
                image: this.state.image,
                metaTitle: this.state.metaTitle,
                metaKeywords: this.state.metaKeywords,
                metaDescription: this.state.metaDescription,
                order: this.state.order,
            })
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 409) {
                    throw Error("Flokkur með þessum link er nú þegar til");
                }
                else {
                    throw Error("Rejected with code " + response.status);
                }
            })
            .then(data => {
                this.props.history.goBack();
            })
            .catch(error => this.setState({ error, isLoading: false }));
    }

    onSubmit = (event) => {
        if (this.state.isUploading) {
            event.preventDefault()
        } else {
            this.setState({ isSubmitted: true });
            if (this.state.creating) {
                this.createCategory(event);
            } else {
                this.updateCategory(event);
            }
        }
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        if (params.id !== 'create') {
            this.fetchSite("/" + params.id);
        } else {
            this.setState({
                isLoading: false,
                creating: true,
            });
        }
    }
    
    componentWillUnmount() {
        let imageToDelete;
        if (!this.state.isSubmitted && this.state.image !== this.state.oldImage) {
            imageToDelete = this.state.image;
        } else if (this.state.image !== this.state.oldImage) {
            imageToDelete = this.state.oldImage
        }
        if (imageToDelete) { ImageService.DeleteImage(imageToDelete); }
    }

    componentWillReceiveProps(newProps) {
        this.setState({ error: null, })
        this.fetchSite(newProps.match.url);
    }

    onNameChange = event => {
        this.setState({ name: event.target.value });
    }
    onLinkChange = event => {
        this.setState({ link: event.target.value });
    }
    onDescriptionChange = event => {
        this.setState({ description: event.target.value });
    }
    onMetaTitleChange = event => {
        this.setState({ metaTitle: event.target.value });
    }
    onMetaKeywordsChange = event => {
        this.setState({ metaKeywords: event.target.value });
    }
    onMetaDescriptionChange = event => {
        this.setState({ metaDescription: event.target.value });
    }
    onOrderChange = event => {
        if (!isNaN(event.target.value)) {
            this.setState({
                order: event.target.value,
                error: null,
            });
        }
    }

    async handleUploadImage(image) {
        this.setState({ isUploading: true });
        const folder = "categories"
        this.setState({ oldImage: this.state.image })
        await ImageService.AddSmallImage(image, folder).then(ok => {
            this.setState({ image: ok.location, isUploading: false })
        },
            err => {
                this.setState({ isUploading: false, error: err })
            })
    }

    render() {
        const { error, isLoading, name, link, description, metaTitle, metaKeywords, metaDescription, status, creating, isUploading, image, order } = this.state;
        if (status === 404) {
            return (<div className="Backend">
                <h1>404</h1>
            </div>)
        }
        else {
            return (
                <div className="Backend">
                    <Container>
                        <Row><Col><h1 className="Title">Sendibílaleiga {creating ? ('búa til flokk') : ('breyta flokk')}</h1></Col></Row>
                        {!isLoading ? (

                            <div>
                                <form onSubmit={this.onSubmit}>
                                    <Tabs defaultActiveKey="category" id="categoryAddEditTab">
                                        <Tab eventKey="category" title="Flokkur">
                                            <Row>
                                                <Col>
                                                    {isUploading ? (<ReactLoading type={"spin"} color={"#5b5b5b"} height={100} width={150} />) : (<img src={image} alt={""} />)}
                                                </Col>
                                                <Col>
                                                    <Dropzone
                                                        onDrop={this.handleUploadImage.bind(this)}
                                                        accept="image/*"
                                                    >
                                                        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                                            <div className="DropZone" {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                {!isDragActive && 'Click here or drop a image to upload!'}
                                                                {isDragActive && !isDragReject && "Drop it like it's hot!"}
                                                                {isDragReject && "File type not accepted, sorry!"}
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                </Col>
                                            </Row>
                                            <label htmlFor="siteTitle">Nafn</label>
                                            <Row>
                                                <Col xs={6}><input className="form-control" type="text" name="title" id="siteTitle" value={name} onChange={this.onNameChange} ></input> </Col>
                                            </Row>
                                            <label htmlFor="catOrder">Röðun</label>
                                            <Row>
                                                <Col xs={6}><input className="form-control" type="text" name="order" id="catOrder" value={order} onChange={this.onOrderChange} ></input> </Col>
                                            </Row>
                                            <label htmlFor="siteHtml">Lýsing</label>
                                            <Row>
                                                <Col><textarea name="Description" className="_textBox" rows="10" id="Description" value={description} onChange={this.onDescriptionChange}></textarea></Col>
                                            </Row>
                                        </Tab>
                                        <Tab eventKey="settings" title="Stillingar">
                                            <label htmlFor="siteLink">Link</label>
                                            <Row>
                                                <Col xs={6}><input className="form-control" type="text" name="link" id="siteLink" value={link} onChange={this.onLinkChange}></input></Col>
                                            </Row>
                                            <label htmlFor="siteMetaTitle">Meta Title</label>
                                            <Row>
                                                <Col xs={6}><input className="form-control" type="text" name="meta-title" id="siteMetaTitle" value={metaTitle} onChange={this.onMetaTitleChange} ></input> </Col>
                                            </Row>
                                            <label htmlFor="siteMetaKeywords">Meta Keywords</label>
                                            <Row>
                                                <Col xs={6}><input className="form-control" type="text" name="meta-Keywords" id="siteMetaKeywords" value={metaKeywords} onChange={this.onMetaKeywordsChange} ></input> </Col>
                                            </Row>
                                            <label htmlFor="siteMetaDescription">Meta Description</label>
                                            <Row>
                                                <Col><textarea name="Description" className="_textBox" rows="10" id="siteMetaDescription" value={metaDescription} onChange={this.onMetaDescriptionChange}></textarea></Col>
                                            </Row>
                                        </Tab>
                                    </Tabs>
                                    <Row>
                                        <Col xs={10}>{error ? <p className="Error">Oops eitthvað fór úrskeiðis: {error.message}</p> : null}</Col>
                                        <Col xs={2}><button className="submitButton" type="submit">SUBMIT</button></Col>
                                    </Row>
                                </form>
                            </div>
                            // If there is a delay in data, let's let the user know it's loading
                        ) : (
                                <h3>Loading...</h3>
                            )}
                    </Container>
                </div>
            );
        }
    }
}

export default categoryAddEdit;