import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './Backend.css';

require('dotenv').config();

class BackendList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            isLoading: true,
            error: null,
            status: 200,
            show: false,
            nameToDelete: null,
            deleteInput: '',
            idToDelete: null,
            deleteVerify: null,
            page: 1,
        }
    }

    getInitialState() {
        return { show: false };
    }

    showModal(id, title) {
        this.setState({
            show: true,
            idToDelete: id,
            nameToDelete: title,
        });
    }

    hideModal() {
        this.setState({ show: false });
    }

    fetchList(path, page) {
        // Where we're fetching data from api+

        fetch(process.env.REACT_APP_NODE_BACKEND + path + '?page=' + page, {
            method: 'GET',
            credentials: 'include',
        })
            // We get the API response and receive data in JSON format...
            .then(response => {
                this.setState({ status: response.status })
                return response.json();
            })
            // ...then we update the users state
            .then(data => {
                this.setState({
                    data: data,
                    isLoading: false,
                })
            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }


    componentDidMount() {
        const { match: { params } } = this.props;
        const { page } = this.state.page;
        this.fetchList("/" + params.link, page);
    }

    verifyDelete = event => {
        this.setState({ deleteInput: event.target.value });
    }

    deleteItem() {
        const { idToDelete, deleteInput, nameToDelete } = this.state;
        const { match: { params } } = this.props;
        if (deleteInput === nameToDelete) {
            fetch(process.env.REACT_APP_NODE_BACKEND + '/' + params.link + '/' + idToDelete, {
                method: 'DELETE',
                credentials: 'include',
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    else {
                        throw Error("Rejected with code " + response.status);
                    }

                })
                .then(data => {
                    this.fetchList("/" + params.link);
                    this.setState({ show: false, deleteInput: "" });
                })
                .catch(error => this.setState({ error, isLoading: false }));
        }
    }

    createListItem(item) {
        const { match: { params } } = this.props;
        return (
            <Row key={item._id}>
                <Col xs={10}>
                    <Link to={"/admin/" + params.link + "/" + item._id}><div className="ListItem">{item.dateCreated !== undefined ? ('Nafn: ' + item.name + ' Dagsetning: ' + item.dateCreated): ('Nafn: ' + item.name)}</div></Link>
                </Col>
                {item.default === false || !item.default ? (
                    <Col xs={2}>
                        <button className="Deletebtn" onClick={() => this.showModal(item._id, item.name)}><FontAwesomeIcon icon="trash-alt" /></button>
                    </Col>
                ) : (null)}
            </Row>
        );
    }

    render() {
        const { error, isLoading, data, status, deleteInput, nameToDelete, page } = this.state;
        const { match: { params } } = this.props;
        let maxPage = 0;
        if (!isLoading && data) {
            var listItems = data.slice((page - 1) * 15, page * 15).map(this.createListItem.bind(this));
            maxPage = Math.ceil(data.length / 15);
        }
        if (status === 404) {
            return (
                <div>
                    <h1>404</h1>
                </div>
            )
        }
        else {
            return (
                <div className="Backend">
                    <Container>
                        <Row><Col><h1 className="Title">Sendibílaleiga {params.link}</h1></Col></Row>
                        {error ? <p className="Error">Oops eitthvað fór úrskeiðis hér {error.message}</p> : null}
                        {!isLoading ? (
                            <div>
                                <Modal
                                    show={this.state.show}
                                    onHide={this.hideModal.bind(this)}
                                    dialogClassName="modal-122w"
                                    aria-labelledby="example-custom-modal-styling-title"
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title id="example-custom-modal-styling-title">Eyða</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <h4>ERTU VISS?</h4>
                                        <p>Að eyða hlut er varanlegt, ekki er hægt að afturkalla því sem hefur verið eytt. Viltu eyða (<strong>{nameToDelete}</strong>)
                                            skrifaðu það hér fyrir neðan til að staðfesta að þú viljir eyða því:
                                    </p>
                                        <input type="text" value={deleteInput} onChange={this.verifyDelete} />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button disabled={deleteInput !== nameToDelete} onClick={this.deleteItem.bind(this)}>Eyða</Button>
                                        <Button onClick={this.hideModal.bind(this)}>Close</Button>
                                    </Modal.Footer>
                                </Modal>
                                {params.link !== 'requests' && params.link !== 'bookings' ? (<Row><Col><Link to={"/admin/" + params.link + "/create"}><button className="BaetaVid">Bæta við</button></Link></Col></Row>) : (null)}
                                {maxPage !== 1 ? (<div><button className="PrevPage BaetaVid" onClick={() => this.setState({ page: page === 1 ? 1 : page - 1 })}>Baka</button>
                                Blaðsíða {page} af {maxPage}
                                <button className="NextPage BaetaVid" onClick={() => this.setState({ page: page === maxPage ? maxPage : page + 1 })}>Næsta</button></div>) : null}
                                {listItems}
                            </div>
                            // If there is a delay in data, let's let the user know it's loading
                        ) : (
                                <h3>Loading...</h3>
                            )}
                    </Container>
                </div>

            );
        }
    }
}

export default BackendList;