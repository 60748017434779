import React, { Component } from 'react';

import './Backend.css';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import ReactLoading from 'react-loading';
import { Editor } from '@tinymce/tinymce-react';

import ImageService from '../../services/ImageService';

require('dotenv').config();

class AddEditCar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: null,
            response: null,
            name: '',
            image: '',
            kmIncluded: '',
            description: '',
            carClass: '',
            locationsChecked: [],
            locations: [],
            categoriesChecked: [],
            categories: [],
            isLoading: true,
            isUploading: false,
            isSubmitted: false,
            error: null,
            status: 200,
            order: 0,
        }
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        this.fetchCategories();
        this.fetchLocations();
        if (params.id !== 'create') {
            this.setState({id: params.id});
            this.fetchCar("/" + params.id);
        } else {
            this.setState({
                isLoading: false,
                creating: true,
            });
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({ error: null, })
        this.fetchCar(newProps.match.url);
    }

    componentWillUnmount() {
        let imageToDelete;
        if (!this.state.isSubmitted && this.state.image !== this.state.oldImage) {
            imageToDelete = this.state.image;
        } else if(this.state.image !== this.state.oldImage){
            imageToDelete = this.state.oldImage
        }
        if(imageToDelete) {ImageService.DeleteImage(imageToDelete); }
    }

    fetchCar(id) {
        // Where we're fetching data from api+
        fetch(process.env.REACT_APP_NODE_BACKEND + '/cars' + id)
            // We get the API response and receive data in JSON format...
            .then(response => {
                this.setState({ status: response.status })
                return response.json();
            })
            // ...then we update the users state
            .then(data => {
                this.setState({
                    response: data,
                    name: data.name,
                    image: data.image,
                    oldImage: data.image,
                    kmIncluded: data.kmIncluded,
                    description: data.description,
                    locationsChecked: data.locations,
                    carClass: data.class,
                    isLoading: false,
                    creating: false,
                    order: data.order,
                })
            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }

    fetchLocations() {
        fetch(process.env.REACT_APP_NODE_BACKEND + '/locations')
            // We get the API response and receive data in JSON format...
            .then(response => {
                this.setState({ status: response.status })
                return response.json();
            })
            // ...then we update the users state
            .then(data => {
                this.setState({
                    locations: data,
                })
            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }

    fetchCategories() {
        fetch(process.env.REACT_APP_NODE_BACKEND + '/categories')
            // We get the API response and receive data in JSON format...
            .then(response => {
                this.setState({ status: response.status })
                return response.json();
            })
            // ...then we update the users state
            .then(data => {
                this.setState({
                    categories: data,
                })
                let categories = [];
                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    if(element.cars.includes(this.state.id)) {
                        categories.push(element._id);
                    }
                }
                this.setState({categoriesChecked: categories})
            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }

    createCar = (event) => {
        event.preventDefault();
        fetch(process.env.REACT_APP_NODE_BACKEND + '/cars', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({
                name: this.state.name,
                image: this.state.image,
                kmIncluded: this.state.kmIncluded,
                class: this.state.carClass,
                description: this.state.description,
                locations: this.state.locationsChecked,
                categories: this.state.categoriesChecked,
                order: this.state.order,
            })
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw Error("Rejected with code " + response.status);
                }
            })
            .then(data => {
                this.props.history.goBack();
            })
            .catch(error => this.setState({ error, isLoading: false }));
    }

    updateCar = (event) => {
        event.preventDefault();
        const { match: { params } } = this.props;
        fetch(process.env.REACT_APP_NODE_BACKEND + '/cars/' + params.id, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({
                name: this.state.name,
                image: this.state.image,
                kmIncluded: this.state.kmIncluded,
                class: this.state.carClass,
                description: this.state.description,
                locations: this.state.locationsChecked,
                categories: this.state.categoriesChecked,
                order: this.state.order,
            })
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw Error("Rejected with code " + response.body);
                }
            })
            .then(data => {
                this.props.history.goBack();
            })
            .catch(error => this.setState({ error, isLoading: false }));
    }

    onSubmit = (event) => {
        if (this.state.isUploading) {
            event.preventDefault()
        } else {
            this.setState({ isSubmitted: true });
            if (this.state.creating) {
                this.createCar(event);
            } else {
                this.updateCar(event);
            }
        }
    }

    onNameChange = event => {
        this.setState({ name: event.target.value });
    }
    onClassChange = event => {
        this.setState({ carClass: event.target.value });
    }
    onDescChange = event => {
        this.setState({ description: event.target.getContent() });
    }
    onKmChange = event => {
        if (!isNaN(event.target.value)) {
            this.setState({
                kmIncluded: event.target.value,
                error: null,
            });
        }
    }
    onLocationChange(event) {
        let checkedArray = this.state.locationsChecked;
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            checkedArray.push(selectedValue);
            this.setState({
                locationsChecked: checkedArray
            });
        } else {
            let valueIndex = checkedArray.indexOf(selectedValue);
            checkedArray.splice(valueIndex, 1);
            this.setState({
                locationsChecked: checkedArray
            });
        }
    }

    onCategoryChange(event) {
        let checkedArray = this.state.categoriesChecked;
        let selectedValue = event.target.value;
        if (event.target.checked === true) {
            checkedArray.push(selectedValue);
            this.setState({
                categoriesChecked: checkedArray
            });
        } else {
            let valueIndex = checkedArray.indexOf(selectedValue);
            checkedArray.splice(valueIndex, 1);
            this.setState({
                categoriesChecked: checkedArray
            });
        }
    }

    onOrderChange = event => {
        if (!isNaN(event.target.value)) {
            this.setState({
                order: event.target.value,
                error: null,
            });
        }
    }

    createLocationList(item) {
        let checked = false;
        if (this.state.locationsChecked.includes(item._id)) {
            checked = true;
        }
        return (
            <Row key={item._id}>
                <Col xs={12}>
                    <input type="checkbox" defaultChecked={checked} value={item._id} id={item._id} onChange={this.onLocationChange.bind(this)} /><label htmlFor={item._id}>{item.name}</label>
                </Col>
            </Row>
        );
    }

    createCategoryList(item) {
        let checked = false;
        if (this.state.categoriesChecked.includes(item._id)) {
            checked = true;
        }
        return (
            <Row key={item._id}>
                <Col xs={12}>
                    <input type="checkbox" defaultChecked={checked} value={item._id} id={item._id} onChange={this.onCategoryChange.bind(this)} /><label htmlFor={item._id}>{item.name}</label>
                </Col>
            </Row>
        );
    }

    async handleUploadImage(image) {
        this.setState({ isUploading: true });
        const folder = "cars"
        this.setState({ oldImage: this.state.image })
        await ImageService.AddSmallImage(image, folder).then(ok => {
            this.setState({ image: ok.location, isUploading: false })
        }, 
        err=> {
            this.setState({isUploading: false, error: err})
        })
    }

    render() {
        const { order, error, isLoading, name, description, carClass, kmIncluded, status, creating, locations, categories, image, isUploading } = this.state;
        var locationCheckBoxes = locations.map(this.createLocationList.bind(this));
        var categoryCheckBoxes = categories.map(this.createCategoryList.bind(this));
        
        if (status === 404) {
            return (<div className="Backend">
                <h1>404</h1>
            </div>)
        }
        else {
            return (
                <div className="Backend">
                    <Container>
                        <Row><Col><h1 className="Title">Sendibílaleiga {creating ? ('búa til bíl') : ('breyta bíl')}</h1></Col></Row>
                        {!isLoading && categoryCheckBoxes.length !== 0 && locationCheckBoxes.length !== 0? (

                            <div>
                                <form onSubmit={this.onSubmit}>
                                    <Tabs defaultActiveKey="car" id="BílleAddEditTab">
                                        <Tab eventKey="car" title="Bíll">
                                            <Row>
                                                <Col>
                                                    {isUploading ? (<ReactLoading type={"spin"} color={"#5b5b5b"} height={100} width={150} />) : (<img src={image} alt={""}/>)}
                                                </Col>
                                                <Col>
                                                    <Dropzone
                                                        onDrop={this.handleUploadImage.bind(this)}
                                                        accept="image/*"
                                                    >
                                                        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                                            <div className="DropZone" {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                {!isDragActive && 'Click here or drop a image to upload!'}
                                                                {isDragActive && !isDragReject && "Drop it like it's hot!"}
                                                                {isDragReject && "File type not accepted, sorry!"}
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                </Col>
                                            </Row>
                                            <label htmlFor="carName">Nafn</label>
                                            <Row>
                                                <Col xs={6}><input className="form-control" type="text" name="name" id="carName" value={name} onChange={this.onNameChange} required /></Col>
                                            </Row>
                                            <label htmlFor="kmIncluded">Innifaldir kílómetrar</label>
                                            <Row>
                                                <Col xs={6}><input className="form-control" type="text" name="kmIncluded" id="kmIncluded" value={kmIncluded} onChange={this.onKmChange} required /> </Col>
                                            </Row>
                                            <label htmlFor="carClass">Klasi</label>
                                            <Row>
                                                <Col xs={6}><input className="form-control" type="text" name="carClass" id="carClass" value={carClass} onChange={this.onClassChange} required /> </Col>
                                            </Row>
                                            <label htmlFor="catOrder">Röðun</label>
                                            <Row>
                                                <Col xs={6}><input className="form-control" type="text" name="order" id="catOrder" value={order} onChange={this.onOrderChange} ></input> </Col>
                                            </Row>
                                            <label htmlFor="carDesc">Lýsing</label>
                                            <Row>
                                                <Col>
                                                    <Editor id="siteHtml"
                                                        initialValue={description}
                                                        init={{
                                                            height: '400px',
                                                            plugins: 'autolink link image lists print preview code textcolor table',
                                                            menubar: "file edit insert view format table tools",
                                                            toolbar: 'formatselect | bold italic underline strikethrough forecolor backcolor | link image | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | code'
                                                        }}
                                                        onChange={this.onDescChange}
                                                    /></Col>
                                            </Row>
                                        </Tab>
                                        <Tab eventKey="settings" title="Stillingar">
                                            <label htmlFor="carLocations">Staðsetningar</label>
                                            {locationCheckBoxes}
                                            <label htmlFor="carCategories">Flokkar</label>
                                            {categoryCheckBoxes}
                                        </Tab>
                                    </Tabs>
                                    <Row>
                                        <Col xs={10}>{error ? <p className="Error">Oops eitthvað fór úrskeiðis: {error.message}</p> : null}</Col>
                                        <Col xs={2}><button className="submitButton" type="submit">SUBMIT</button></Col>
                                    </Row>
                                </form>
                            </div>
                            // If there is a delay in data, let's let the user know it's loading
                        ) : (
                                <h3>Loading...</h3>
                            )}
                    </Container>
                </div>
            );
        }
    }
}

export default AddEditCar;