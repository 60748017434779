import React from 'react';
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap';

import './Header.css';
import logo from '../../Images/logo.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Header = () => {
    return (
        <div className="Header">
            <Container fluid={true}>
                <Row>
                    <Col xs={12} md={12} xl={5} > <Link to='/'><img src={logo} alt="logo" className="Logo"/></Link> </Col>
                    <Col md={12} xl={7}>
                        <Container fluid={true}>
                            <Row className="flex-row-reverse" >
                                {/* <Col xl="auto" md="auto" xs={3}><a href='tel:[5157110]'> <div className="uppl"><FontAwesomeIcon size="2x" icon="phone" className="font" /> <p className="infoText" >5157110</p></div> </a></Col> */}
                                <Col xl="auto" md="auto" xs={4}><Link to='/opnunartimi'> <div className="uppl"><FontAwesomeIcon size="2x" icon="clock" className="font" /> <p className="infoText" >Opnunartímar</p></div></Link></Col>
                                <Col xl="auto" md="auto" xs={4}><a href="https://www.facebook.com/sendibilartilleigu"><div className="uppl"><FontAwesomeIcon size="2x" icon={['fab', 'facebook-square']} className="facebook" />
                                <p className="infoText" > Facebook</p></div>
                                </a></Col>
                                <Col xl="auto" md="auto" xs={4}><Link to='/hafa-samband'><div className="uppl"><FontAwesomeIcon size="2x" icon="question-circle" className="questionmark" /> <p className="infoText" >Fyrirspurn</p></div></Link></Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>

    );
};

export default Header;