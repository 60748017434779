import React, { Component } from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import Car from './Car';
import './Category.css';

class Category extends Component {

    constructor(props) {
        super(props);

        this.state = {
            allLocations: null,
            cars: [],
            isLoading: true,
            error: null,
        }
    }

    fetchLocations() {
        fetch(process.env.REACT_APP_NODE_BACKEND + '/locations')
            // We get the API response and receive data in JSON format...
            .then(response => {
                this.setState({ status: response.status })
                return response.json();
            })
            // ...then we update the users state
            .then(data => {
                this.setState({
                    allLocations: data,
                    isLoading: false,
                })
                if (this.state.selectedCarId) {
                    this.setSelectedCar(this.state.selectedCarId);
                }
                if (this.state.selectedLocationId) {
                    this.setSelectedLocation(this.state.selectedLocationId);
                }
            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }

    fetchCars() {
        // Where we're fetching data from api+
        fetch(process.env.REACT_APP_NODE_BACKEND + '/cars/valid')
            // We get the API response and receive data in JSON format...
            .then(response => {
                this.setState({ status: response.status })
                return response.json();
            })
            // ...then we update the users state
            .then(data => {
                this.setState({
                    cars: data.cars,
                    isLoading: false,
                    lastModified: data.lastModified,
                })
            })
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error, isLoading: false }));
    }


    componentDidMount() {
        this.fetchLocations();
        this.fetchCars(this.props.location.pathname);
    }

    componentWillReceiveProps(newProps) {
        this.setState({ error: null, cars: [] })
        this.fetchLocations();
        this.fetchCars(newProps.match.url);
    }


    createCarListItem(item) {
        const {allLocations} = this.state;
        return (
            <Col xs={12} sm={6} md={4} key={item._id} ><Car car={item} allLocations={allLocations} /></Col>
        );
    }

    render() {
        const metaTitle = "Allir bílar | Sendibílar til leigu";
        const metaDescription = "Allir sendibílar til leigu hjá Sendibílar til leigu. Alskonar Rafsendibílar stórir og smáir til leigu strax í dag með stuttum fyrirvara. Bókaðu núna.";
        const { isLoading, cars, error, allLocations, lastModified } = this.state;
        if (!isLoading && cars && allLocations) {
            var carList = cars.sort((a, b) => a.order - b.order).map(this.createCarListItem.bind(this));
        }
        return (
            <div>

                {error ? <p className="Error">Oops eitthvað fór úrskeiðis hér {error.message}</p> : null}
                {!isLoading ? (
                    <div>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>{metaTitle}</title>
                            <meta name="keywords" content={"Allir sendibílar til leigu"} />
                            <meta name="description" content={metaDescription} />
                            <meta property="og:title" content={metaTitle} />
                            <meta property="og:description" content={metaDescription} />
                            <meta property="og:url" content={window.location.href} />
                            <meta name="last-modified" content={lastModified} />
                        </Helmet>
                        <Container fluid={true}>
                            <Row>
                                <Col><h1>Allir sendibílar</h1></Col>
                            </Row>
                            <Row >
                                {carList}
                            </Row>
                        </Container>
                    </div>
                ) : (
                        <h3>Loading...</h3>
                    )}
            </div >
        );
    }

};



export default Category;